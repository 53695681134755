import React from 'react';

const ButtonDownSVG = () => (
  <svg id="buttonSVG-DOWN"  style={{overflow:"visible"}} className="down" data-name="Layer 1"  width="49.7" height="33.3" viewBox="0 0 49.7 33.3">
  	<defs>
			<filter id="sofGlow_down" height="300%" width="300%" x="-75%" y="-75%">
				<feMorphology operator="dilate" radius="0.5" in="SourceAlpha" result="thicken" />
				<feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />
				<feFlood floodColor="#8DFFFB" result="glowColor" />
				<feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />
				<feMerge>
					<feMergeNode in="softGlow_colored"/>
					<feMergeNode in="SourceGraphic"/>
				</feMerge>
			</filter>
  	</defs>
		<polyline points="0.8 0.8 24.4 24.4 25.3 24.4 48.9 0.8" filter="url(#sofGlow_down)" fill="none" stroke="#81edfc" strokeMiterlimit="10" strokeWidth="2.15"/>
		<polyline points="13 20.9 21.6 32.6 28 32.6 36.7 20.9"  fill="none" stroke="#81edfc" strokeMiterlimit="10" strokeWidth="1.53"/>
	</svg>
);

export default ButtonDownSVG;