import React, { useEffect } from 'react';
import { TimelineMax as Timeline } from 'gsap';
import './projects.css'
import { withTranslation} from "react-i18next";



function PhotoViewer(props) {
	const { t } = props;

useEffect(() => {

 const photoViewer = document.querySelectorAll('.photoViewer'); 
 

 const timeline = new Timeline({ paused: true,delay:0.5 });
 timeline
   .to(photoViewer,0.5, { opacity: 1})
   
    timeline.play();

    }, )

	return(

		<div className="photoViewer">

		<div className="work-page-button " onClick={props.close}>{t("Back")}</div>

		<img  src={props.photo}></img>
			
		</div>
		)
		

}

export default(withTranslation("translations")(PhotoViewer))