import React from 'react';
import './Home.css'
import logo from '../Media/img/logo.svg';
import ReactDOM from "react-dom";
import { NavLink } from 'react-router-dom';
import WheelReact from 'wheel-react';
import { TimelineMax as Timeline, Power1} from 'gsap';
import {TweenMax} from 'gsap';
import ButtonDown from '../Buttons/ButtonDown';
import { withTranslation} from "react-i18next";
var posicion = 0;
var animation = false;

class Home extends React.Component {

	constructor(props){
	super(props);
	this.state = {
		homeSection:undefined,
		bulletOpen:false,
			listaLiNav:[]
	}

	this.myRef = React.createRef();
	this.scroller = this.scroller.bind(this);
	this.updateSectionsState = this.updateSectionsState.bind(this);
	this.hoverFlechas = this.hoverFlechas.bind(this);
	this.hoverFlechasLeave = this.hoverFlechasLeave.bind(this);
	this.getLiHomeNav = this.getLiHomeNav.bind(this);
	this.clickBullets = this.clickBullets.bind(this);
	this.completeAnim = this.completeAnim.bind(this);
	}


	componentDidMount(){
		const node = ReactDOM.findDOMNode(this);
		const buttonUp = node.querySelector('#buttonSVG-UP');
		const buttonDown = node.querySelector('#buttonSVG-DOWN');
		window.addEventListener('resize', this.updateWindowDimensions );
		buttonUp.addEventListener('mouseenter', this.hoverFlechas);
		buttonUp.addEventListener('mouseleave', this.hoverFlechasLeave);
		buttonDown.addEventListener('mouseenter', this.hoverFlechas);
		buttonDown.addEventListener('mouseleave', this.hoverFlechasLeave);
		this.getLiHomeNav();
	}

	hoverFlechas(e){
		const svgFlecha = e.currentTarget;
		const timeline = new Timeline({ paused: true });
		if(svgFlecha.className.baseVal === "up"){
			timeline.to(svgFlecha, 0.3, {y:-10,ease: Power1.easeInOut});
			timeline.play();
		}
		else if (svgFlecha.className.baseVal === "down"){
			timeline.to(svgFlecha, 0.3, {y:10,ease: Power1.easeInOut});
			timeline.play();
		}
	}

	hoverFlechasLeave(e){
		const svgFlecha = e.currentTarget;
		const timeline = new Timeline({ paused: true });
		if (svgFlecha.className.baseVal === "up") {
		timeline.to(svgFlecha, 0.3, {y:0,ease: Power1.easeInOut});
			timeline.play();
		}
		else if (svgFlecha.className.baseVal === "down") {
			timeline.to(svgFlecha, 0.3, {y:0,ease: Power1.easeInOut});
			timeline.play();
		}
	}

	componentWillUnmount() {
		WheelReact.clearTimeout();
		posicion = 0;
	}


	updateSectionsState(direccion,posicionLi){
		const node = ReactDOM.findDOMNode(this);
		const secciones = node.querySelectorAll('.home-sections');
		const navVertical = node.querySelector('.titulos-sections-home');
		const liNav = node.querySelectorAll('.home-nav-li');
		const numeroSecciones = secciones.length;
		const buttonUp = node.querySelectorAll('.button-up');
		const buttonDown = node.querySelectorAll('.button-down');
		const timeline = new Timeline({ paused: true });
		const tope = numeroSecciones - 1;
		//console.log(numeroSecciones);

		if (!animation){
			if (direccion === "down"){
				if(posicion < numeroSecciones - 1 ){
					posicion++
					this.setState({homeSection:posicion})
					setTimeout(this.scrollerAnim(secciones[posicion],secciones,navVertical,liNav),1000) 
				}
			}
			else if(direccion === "up"){
				if(posicion > 0){
					posicion--
					this.setState({homeSection:posicion}) 
					setTimeout(this.scrollerAnim(secciones[posicion],secciones,navVertical,liNav),1000) 
				}
					}
			else{
				posicion = posicionLi;
				this.setState({homeSection:posicion}) 
				setTimeout(this.scrollerAnim(secciones[posicion],secciones,navVertical,liNav),1000) 
			} 	
						
			if (posicion === 0) {
				timeline.to([buttonUp , navVertical ], 0.5, {opacity:0,ease: Power1.easeInOut});
			timeline.play();
			}
			else if (posicion === tope ){
				timeline.to(buttonDown, 0.5, {opacity:0,ease: Power1.easeInOut});
			timeline.play();
			}
			else{
				timeline.to([buttonUp , navVertical ], 0.5, {opacity:1,ease: Power1.easeInOut})
				.to(buttonDown, 0.5, {opacity:1,ease: Power1.easeInOut});
			timeline.play();
			}
		}
	}

	scroller(posicion){
		this.updateSectionsState(undefined,posicion);
	}
	/////////Sacroll Anim//////////////////////////////////////////////////////
	scrollerAnim (sectionToScroll,secciones,navVertical,liNav){
		if (!animation){
			animation = true;
			for (var i = 0; i < secciones.length; i++ ) {		
				secciones[i].className = secciones[i].className.replace("section-active", "");
				liNav[i].className = liNav[i].className.replace("home-nav-li-active", "");
			}
			sectionToScroll.className += " section-active";
			liNav[posicion].className += " home-nav-li-active";
			var staggersHomeElements = sectionToScroll.getElementsByClassName("staggers-anim");
			var titulo = sectionToScroll.id;
			this.setState({homeSection:titulo});
			const timeline = new Timeline({ paused: true });
			timeline.staggerFrom(staggersHomeElements, 0.5,{y:+40,opacity:0,ease: Power1.easeInOut},0.3,0.75,this.completeAnim);
			timeline.play();
		}
	}

	clickBullets(e){
		const node = ReactDOM.findDOMNode(this);
		const target = e.currentTarget;
		const bullets = node.querySelectorAll('.bullets-h2');
		const bulletsTexts = node.querySelectorAll('.bullets-text');
		const especials = node.querySelectorAll('.cont-especial');
		const especialsActive = node.querySelector("#especial-" + target.id);
		const activeBullets = especialsActive.querySelectorAll(".especial-bullets");	

		if (!animation){
			animation = true;
			for (var i = 0; i < bullets.length; i++ ) {		
			bullets[i].className = bullets[i].className.replace("bullets-active", "");
			bulletsTexts[i].className = bulletsTexts[i].className.replace("bullets-text-active", "");
			especials[i].className = especials[i].className.replace("cont-especial-active", "");
			}
			target.className += " bullets-active";
			e.currentTarget.querySelector(".bullets-text").className += " bullets-text-active";
			especialsActive.className += " cont-especial-active";
			TweenMax.staggerFrom(activeBullets, 0.5,{y:+10,opacity:0,ease: Power1.easeInOut},0.3,this.completeAnim);
		}
	}

	completeAnim (){
	animation = false;
	}
	getLiHomeNav(){
		const node = ReactDOM.findDOMNode(this);
		const secciones = node.querySelectorAll('.home-sections');
		for (var i = 0; i < secciones.length; i++ ) { 
			this.state.listaLiNav.push(i);
		}
	}

	render(){
		const { t } = this.props;
		const liNavHome = this.state.listaLiNav.map(item => <li onClick={() => {this.scroller(item)} } key={item} id={"li"+item} className="home-nav-li"></li> );

		WheelReact.config({
			up: () => {
				this.updateSectionsState("down");
			},
			down: () => {
					this.updateSectionsState("up"); 
			}
	});


		let homeSection = this.state.homeSection;
		let home;
		
	///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
			home = (
				<div  className="home-cont" {...WheelReact.events} >
					<div className="home-0 home-sections section-active"   >
						<div className="home-0-sub-cont" >
							<div ><img className="logo-home staggers staggers-anim"  src={logo} alt="diego"/></div>
							<ButtonDown direction="down" handleScroll={() => {this.updateSectionsState("down")} } />
						</div>
					</div>
					<div className="contenedor-secciones-1234"> 
					<div className="titulos-sections-home staggers-anim">
						<div id="home-navigator">{liNavHome}</div>
						<div className="titulo-home-cont">{homeSection}</div>
					</div>
					<div className="buttons button-up"><ButtonDown  direction="up"  button="home" handleScroll={() => {this.updateSectionsState("up")}} /></div>
					<div  className="home-1 home-sections " id={t("Intro")}>
						<div className="subsections-home-cont">
							<div className="subsections-home first-subsection">
							<div className="imDiego staggers-anim">{t("Hi! I'm Diego")}</div>
							<h1 className="staggers-anim">{t("FRONT END DEVELOPER")}/<span id="link">{t("Designer")}</span></h1>
							<div className="textos-home staggers-anim">
								{t("Great experience in web and audiovisual companies")}<br></br>
								{t("Multidisciplinary, creative, responsible")}<br></br>
								{t("Always looking for a ")}<span>{t("new challenge")}</span>
							</div>
							</div>
							<div className="subsections-home home-1-r staggers-anim"></div>
						</div>
					</div>

					<div className="home-2  home-sections  h-2-cont" id={t("Especialities")} >
						<div className="subsections-home-cont ">
							<div className="subsections-home-2 first-subsection2">
								<div className="h-2 staggers-anim">{t("I")}<span>{t("Do")}</span></div>
							</div>
							<div className="subsections-home-2 home-2-r">
								<div  onClick={this.clickBullets} id="web-select" className="bullets-h2 web staggers-anim">
									<div className="bullets-text ">{t("Web Development")}</div>
								</div>
								<div  onClick={this.clickBullets} id="design-select"  className="bullets-h2 design staggers-anim">
									<div  className="bullets-text"><b>{t("Design / ")}</b>3d</div>
								</div>
								<div  onClick={this.clickBullets}   id="audio-select" className="bullets-h2 audio staggers-anim"><div  className="bullets-text">{t("Sound edition")}</div></div>
								<div  onClick={this.clickBullets} id="photo-select" className="bullets-h2 photo staggers-anim"><div  className="bullets-text">{t("Photography")}</div></div>
							</div>
							<div className="subsections-home-2 home-2-3  ">
								<div className="cont-especial" id="especial-web-select">
									<div className="especial-bullets">Front-end</div>
									<div className="especial-bullets">{t("Web Apps")}</div>
									<div className="especial-bullets">Landing Pages</div>
									<div className="especial-bullets">{t("Display Campaigns")}</div>
									<div className="especial-bullets">Email Marketing</div>
								</div>
								<div className="cont-especial" id="especial-design-select">
									<div className="especial-bullets">{t("Prototypes")}</div>
									<div className="especial-bullets">Wireframes</div>
									<div className="especial-bullets">{t("Graphic Design")}</div>
									<div className="especial-bullets">Animations</div> 
									<div className="especial-bullets">{t("Illustrations")}</div>
									<div className="especial-bullets">Digital Painting</div>
									<div className="especial-bullets">Motion Graphics</div>
									<div className="especial-bullets">{t("3d-Modelling")}</div>
								</div>
								<div className="cont-especial" id="especial-audio-select">
									<div className="especial-bullets">{t("Audio Post-production")}</div>
									<div className="especial-bullets">{t("Gaming Sound Design")}</div>
								</div>
								<div className="cont-especial" id="especial-photo-select">
									<div className="especial-bullets">Post-production</div>
									<div className="especial-bullets">{t("Photography for web")}</div>
								</div>
							</div>
						</div>
					</div>
					<div className="home-3 home-sections" id={t("About Me")} >
						<div className="subsections-home-cont home-3-sub-cont first-subsection">
							<div className="subsections-home ">
								<div className="header-aboutme staggers-anim">{t("A little about me")}:</div>
								<div className="text-aboutme staggers-anim">
									<p>{t("about_me_1")}</p>
									<p>{t("about_me_2")}</p>
									<p>{t("about_me_3")}</p>
									<p>{t("about_me_4")}</p>
								</div>
							</div>
							<div className="subsections-home ">
								<NavLink onClick={() => this.props.handleClickNav("Contact")} strict exact to={"/contact"} ><div className=" staggers-anim"><div className="button-hireMe">{t("Want to talk?")}</div></div></NavLink>
							</div>
						</div>
					</div>
					<div className="buttons button-down"><ButtonDown   direction="down" button="normal"  handleScroll={() => {this.updateSectionsState("down")} } /></div>
					</div>
				</div>)
	////////////////////////////////////////////////////////////////////////
		return <div >{home}</div>
		}
}

export default (withTranslation("translations")(Home));



