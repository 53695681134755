import React from 'react';
import './projects.css'
import data from '../data/data'
import { NavLink } from 'react-router-dom';
import { withTranslation } from "react-i18next";


class Guitar extends React.Component {


render(){
const { t } = this.props;
const category = this.props.category;
const index = this.props.index;
const project = data[category][index];
//let imagesGalery = 0;

const galery = project.expandImgArray.map(item => <div key={item}  className="cont-img-galery"><img src={"../" + item} alt="Guitar" className="img-galery"></img></div> );
  
	return (
		<div className="work-page-cont Guitar">
    <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      
        <div className="work-page-subcont dark">
        
        <img className="main-image" src={"../" + project.imgCabecera} alt="Guitar"></img>
        </div>

        <div className="work-page-project dark">

              <div className="project-header-cont">
                    <div className="project-header-subcont">
                        <p className="project-header">{t("Project")}</p>
                        <p className="project-description-text">Web App: <a className="links-webs-projects" href="https://www.diegopiscitelli.com/project-lab/guitar/index.html">Visit here</a></p>
                    </div>

                     <div className="large-description">{t("Project still")}

              </div>
              </div>

              <div className="project-bullets">
                  <div className="project-bullets-header">{t("Tools")}:</div>
                   <ul>
                        <li>React.js</li>
                        <li>Responsive</li>
                        <li>SVG</li>
                        <li>Illustrator</li>
                  </ul>
              </div>

             

        </div>

        <div className="work-page-galery dark" >

         {galery}

        </div>
       <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      </div>
		)
}
}


export default (withTranslation("translations")(Guitar));