import React from 'react';
import logo from '../Media/img/logo.svg'
import { NavLink } from 'react-router-dom';
import { withTranslation } from "react-i18next";
import Lang  from '../Lang/Lang';
import './NavBar.css'

class NavBar extends React.Component {
	
	render() {
		
		const { t } = this.props;
		var links;
		var linksMobile;
		links = [
			<NavLink className="Home"  onClick={() => this.props.handleClickNav("Home")} key="/"  strict exact to="/" >{t("NavBar.Home")}</NavLink>,
			<NavLink className="Experience"  onClick={() => this.props.handleClickNav("Experience")} key="/experience"  strict exact to="/experience" >{t("NavBar.Experience")}</NavLink>,
			<NavLink className="Works"  onClick={() => this.props.handleClickNav("Works")} key="/works"  strict exact to="/works" >{t("NavBar.Works")}</NavLink>,
			<NavLink className="Contact"  onClick={() => this.props.handleClickNav("Contact")} key="/contact"  strict exact to="/contact" >{t("NavBar.Contact")}</NavLink>
		]
		linksMobile = [
			<NavLink className="Home"  onClick={() => this.props.handleNavResp("Home")} key="/"  strict exact to="/" >{t("NavBar.Home")}</NavLink>,
			<NavLink className="Experience"  onClick={() => this.props.handleNavResp("Experience")} key="/experience"  strict exact to="/experience" >{t("NavBar.Experience")}</NavLink>,
			<NavLink className="Works"  onClick={() => this.props.handleNavResp("Works")} key="/works"  strict exact to="/works" >{t("NavBar.Works")}</NavLink>,
			<NavLink className="Contact"  onClick={() => this.props.handleNavResp("Contact")} key="/contact"  strict exact to="/contact" >{t("NavBar.Contact")}</NavLink>
		]

		

		return (
			<div>
				<div className={`cont-nav ${this.props.needBackgorund ? 'cont-nav-scroll' : ''}`}>
					<div className="logo-nav-cont"><img alt="Piscitelli Design" className="logo-nav" src={logo}/></div>
					<nav>
						{ links } 
						<svg id="Layer_1" className="nav-pointer" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"  >
						
							<title>nav-pointer</title>

									<defs>

										<filter id="sofGlow" height="300%" width="300%" x="-75%" y="-75%">
										
											<feMorphology operator="dilate" radius="0.5" in="SourceAlpha" result="thicken" />

										
											<feGaussianBlur in="thicken" stdDeviation="3" result="blurred" />

										
											<feFlood floodColor="#8DFFFB" result="glowColor" />

										
											<feComposite in="glowColor" in2="blurred" operator="in" result="softGlow_colored" />

										
											<feMerge>
												<feMergeNode in="softGlow_colored"/>
												<feMergeNode in="SourceGraphic"/>
											</feMerge>

										</filter>

									

								</defs>

								<polyline className="select-l" filter="url(#sofGlow)" points="8.6 0.4 0.6 8.4 0.6 14.7 8.6 23.6" fill="none" stroke="#81EDFC" strokeMiterlimit="10" strokeWidth="1.25"/>
								<polyline className="select-r" filter="url(#sofGlow)" points="0.6 23.6 8.6 15.6 8.6 9.4 0.6 0.4" fill="none" stroke="#81EDFC" strokeMiterlimit="10" strokeWidth="1.25"/>

							
						</svg>
					</nav>
					{this.props.screenWidth > 1025 ? <Lang handleLangEvent={this.props.handleLangEvent} /> : null }
				</div>
				<div className="nav-lineas-cont"  onClick={() => this.props.handleNavResp(this.props.section)}>
					<svg id="flecha-nav"  data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
						<line id="linea_a" x1="1.9" y1="5.3" x2="28.1" y2="5.3" fill="none" stroke="#81EDFC" strokeMiterlimit="10" strokeWidth="2"/>
						<line id="linea_b" x1="1.9" y1="14.8" x2="28.1" y2="14.8" fill="none" stroke="#81EDFC" strokeMiterlimit="10" strokeWidth="2"/>
						<line id="linea_c" x1="1.9" y1="24.7" x2="28.1" y2="24.7" fill="none" stroke="#81EDFC" strokeMiterlimit="10" strokeWidth="2"/>
					</svg>
				</div>
				<div className="nav-resp" ref="navResp">{ linksMobile }<Lang handleLangEvent={this.props.handleLangEvent} /></div>
			</div>
		)
	}
}

export default (withTranslation("translations")(NavBar));
