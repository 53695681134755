import React from 'react';
class Lang extends React.Component {
 render() {
	return (
			<div className="lang-cont">
				<div className="lang-links"  onClick={() =>this.props.handleLangEvent("en")} >En/</div>
				<div className="lang-links" onClick={() =>this.props.handleLangEvent("es")} >Es</div>
			</div>
		)
	}
}
export default Lang;