import React from 'react';
import './projects.css'
import data from '../data/data'
import { NavLink } from 'react-router-dom';
import { withTranslation } from "react-i18next";

class Vlp extends React.Component {



render(){
const { t } = this.props;
const category = this.props.category;
const index = this.props.index;
const project = data[category][index];

const galery = project.expandImgArray.map(item => <div key={item} className="cont-img-galery"><img src={"../" + item} alt="Vlp" className="img-galery"></img></div> );
  
	return (
		<div className="work-page-cont ">
    <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      
        <div className="work-page-subcont light">
       
        <img className="main-image" src={"../" + project.imagen} alt="Vlp"></img>
        </div>

        <div className="work-page-project light">

              <div className="project-header-cont">
                    <div className="project-header-subcont">
                        <p className="project-header">{t("Project")}</p>
                        <p className="project-description-text">{t("Booking website 2019")}</p>
                    </div>

                     <div className="large-description">
                     {t("Design and development of the corporative website, brand image")}<br></br>{t("Email campaign")}<br></br>{t("Google display banners campaign")}
                    </div>
              </div>

              <div className="project-bullets">
                  <div className="project-bullets-header">{t("Highlights")}:</div>
                   <ul> 
                        <li>{t("Booking Form")}</li>
                        <li>Responsive</li>
                        <li>Svg</li>
                                 
                  </ul>
              </div>

             

        </div>

        <div className="work-page-galery light" >

         {galery}

        </div>
        <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>

      </div>
		)
}
}


export default (withTranslation("translations")(Vlp));