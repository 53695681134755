import React from 'react';
import './projects.css'
import data from '../data/data'

import { TimelineMax as Timeline} from 'gsap';
import { NavLink } from 'react-router-dom';
import { withTranslation} from "react-i18next";
import PhotoViewer from './PhotoViewer';
class Photo extends React.Component {

constructor (props){
    super(props);
    this.state ={
      photoViewer:false,
      photoActivated:undefined,
      photoClicked:undefined
    }
  }


componentDidMount(){
let cuadros = document.querySelectorAll('.cont-img-galery-photo');

cuadros.forEach(function (item){
let randomHeight = Math.floor(Math.random()* (500 - 300 + 1)+ 300);
item.style.height = randomHeight +"px";
});
}


render(){
const { t } = this.props;
const category = this.props.category;
const index = this.props.index;
const project = data[category][index];


let openPhoto =(item,event) =>{

let contgalery = document.querySelector(".work-page-galery-photo");
let buttons = document.querySelectorAll(".work-page-button");

const timeline = new Timeline({ paused: true });
timeline.to(contgalery,0.5, { opacity: 0})
.to(buttons,0.5, { opacity: 0},0)
timeline.play();


this.setState({photoViewer:true,photoActivated:item,photoClicked:event.currentTarget})

setTimeout(()=>{contgalery.style.display="none";},600)




}

let closePhoto =(item,event) =>{

let contgalery = document.querySelector(".work-page-galery-photo");
let buttons = document.querySelectorAll(".work-page-button");
this.setState({photoViewer:false})
contgalery.style.display="flex";
this.state.photoClicked.scrollIntoView(false);

const timeline = new Timeline({ paused: true });
timeline.to(contgalery,1, { opacity: 1})
.to(buttons,0.5, { opacity: 1})
timeline.play();
}

let lazyLoad = (event)=>{
 
  let loader = event.currentTarget.previousSibling;
  const timeline = new Timeline({ paused: true });
  timeline.to(loader,3, { opacity: 0})
  timeline.play();
}


const galery = project.expandImgArray.map((item, index)=>
  <div key={index} className="cont-img-galery-photo " >
    <div className="cont_img_loader">
      {<div className="loaderImg">Loading</div>}
      <img src={"../" + item } style={{display:"none"}}  onLoad={(event) =>{lazyLoad(event)}}  alt={item}></img>
      <div style={{backgroundImage:"url("+item +")"}}   onClick={(event) =>{openPhoto(item,event)}}  className="img-galery-photo"></div>
    </div>
  </div>
  );

var array0 = [];
var array1 = [];
var array2 = [];
var col = 0;
for (var i = 0; i < galery.length; i++) {
  

  if (col === 1){
    array1.push(galery[i]);
    col = 2;
  }
  else if(col === 2){
    array2.push(galery[i]);
    col = 0;
  }
  else{
    array0.push(galery[i]);
    col = 1;
  }
}






let distribute = (arraySelected)=>{

return arraySelected;
}


	return (
		<div className="work-page-cont">
     {this.state.photoViewer? <PhotoViewer photo={this.state.photoActivated} close={closePhoto} /> : null}
    <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      {/*
         <div className="work-page-subcont dark">
        <div className="headers-work-page">{project.header}</div>
        <img className="main-image" src={"../" + project.imagen} alt="Photo"></img>
        </div>
      */}
       
        <div className="work-page-galery-photo dark" >
          <div className="column" id="col-1">
             {distribute(array0)}
          </div>

          <div className="column">
           {distribute(array1)}
          </div>
          <div className="column">
           {distribute(array2)}
          </div>
        </div>

        <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>

      </div>
		)
}
}


export default (withTranslation("translations")(Photo));