import React from 'react';
import ButtonDownSVG from './ButtonDown_SVG';
import ButtonUpSVG from './ButtonUp_SVG';
class ButtonDown extends React.Component {
 render() {
	return (
		<div onMouseEnter={this.props.handleHoverFlechas}  onClick={this.props.handleScroll}>
		<div>{ this.props.direction === "down" ? <ButtonDownSVG  /> : <ButtonUpSVG /> }</div>
		</div>
		)
	}
}
export default ButtonDown;