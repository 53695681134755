import React from 'react';
import './Works.css';
import Work from './Work';
import WheelReact from 'wheel-react';
import { Transition, TransitionGroup } from 'react-transition-group';
import data from '../data/data'
import {enterSlider, exitSlider, hoverWorksIN, hoverWorksOut,enterCover,exitCover,enterGrid,exitGrid } from '../App/timelines/timelines.js';
import ReactDOM from "react-dom";
import { withTranslation } from "react-i18next";
import { TimelineMax as Timeline} from 'gsap';

//IMGS PRELOAD/////////////////////////////////////////////////////////////////////////////////////
import Guitar_01 from '../Media/img/projects/guitar_01.jpg';
import Guitar_02 from '../Media/img/projects/guitar_02.jpg';
import Guitar_03 from '../Media/img/projects/guitar_03.jpg';
import Guitar_04 from '../Media/img/projects/guitar_04.jpg';
import Guitar_05 from '../Media/img/projects/guitar_05.jpg';
import Guitar_06 from '../Media/img/projects/guitar_06.jpg';
import Guitar_07 from '../Media/img/projects/guitar_07.jpg';
import DigitalInvicta_01 from '../Media/img/projects/digitalInvicta_01.jpg';
import DigitalInvicta_02 from '../Media/img/projects/digitalInvicta_02.jpg';
import DigitalInvicta_03 from '../Media/img/projects/digitalInvicta_03.jpg';
import DigitalInvicta_04 from '../Media/img/projects/digitalInvicta_04.jpg';
import DigitalInvicta_gif_01 from '../Media/img/projects/digitalInvicta_gif_01.gif';
import DigitalInvicta_gif_02 from '../Media/img/projects/digitalInvicta_gif_02.gif';
import SevenAttitudes_01 from '../Media/img/projects/sevenAttitudes_01.jpg';
import SevenAttitudes_02 from '../Media/img/projects/sevenAttitudes_02.jpg';
import SevenAttitudes_03 from '../Media/img/projects/sevenAttitudes_03.jpg';
import Asymmetry_01 from '../Media/img/projects/asymmetry_01.jpg';
import Asymmetry_02 from '../Media/img/projects/asymmetry_02.jpg';
import Asymmetry_03 from '../Media/img/projects/asymmetry_03.jpg';
import Asymmetry_04 from '../Media/img/projects/asymmetry_04.jpg';
import Vlp_01 from '../Media/img/projects/vlp_01.jpg';
import Vlp_02 from '../Media/img/projects/vlp_02.jpg';
import Vlp_03 from '../Media/img/projects/vlp_03.jpg';
import Vlp_04 from '../Media/img/projects/vlp_04.jpg';
import Vlp_05 from '../Media/img/projects/vlp_05.jpg';
import StoryDots_00 from '../Media/img/projects/storyDots_00.jpg';
import StoryDots_01 from '../Media/img/projects/storyDots_01.jpg';
import StoryDots_02 from '../Media/img/projects/storyDots_02.jpg';

import Logos_01 from '../Media/img/projects/logos/logo_blimbo.svg';
import Logos_02 from '../Media/img/projects/logos/logo_cv.svg';
import Logos_03 from '../Media/img/projects/logos/logo_7a.gif';
import Logos_04 from '../Media/img/projects/logos/logo_jutzer.svg';
import Logos_05 from '../Media/img/projects/logos/logo_digitalinvicta.gif';
import Logos_06 from '../Media/img/projects/logos/logo_bonavena.svg';
import Logos_07 from '../Media/img/projects/logos/logo_bossa.jpg';
import Logos_08 from '../Media/img/projects/logos/logo_asymmetry.svg';
import Logos_09 from '../Media/img/projects/logos/logo_melyoga.svg';
import Logos_10 from '../Media/img/projects/logos/logo_santarosa.svg';
import Logos_11 from '../Media/img/projects/logos/logo_mystic.gif';
import Logos_12 from '../Media/img/projects/logos/logo_omystic.gif';
import Batman_01 from '../Media/img/projects/batman_01.jpg';

import Scorpion_02 from '../Media/img/projects/scorpion_02.jpg';
import Scorpion_03 from '../Media/img/projects/scorpion_03.jpg';
import Scorpion_04 from '../Media/img/projects/scorpion_04.jpg';

import Comic_scfi_01 from '../Media/img/projects/comic_scfi_01.jpg';
//import ComingSoon from '../Media/img/projects/comingSoon.jpg';
//IMGS PRELOAD/////////////////////////////////////////////////////////////////////////////////////
var loadedImg = 0;

class Works extends React.Component {

constructor(props) {
	super(props);
	this.state = {
		loading:true,
		category:"web",  
		web: data.web[0],
		design: data.design[0],
		work:data.web[0],
		workGrid:data.web,
		expanded:false
	}
	this.nextWork = this.nextWork.bind(this);
	this.prevWork = this.prevWork.bind(this);
	this.selectCategory = this.selectCategory.bind(this);
	this.hoverWorks = this.hoverWorks.bind(this);
	this.hoverWorksOut = this.hoverWorksOut.bind(this);
	this.handleClickWorks = this.handleClickWorks.bind(this);
	this.selectWorkNavLi = this.selectWorkNavLi.bind(this);
	this.selectCategoryDidMount = this.selectCategoryDidMount.bind(this);
	this.loader = this.loader.bind(this);
	this.onloadImagesTrue = this.onloadImagesTrue.bind(this);
}

componentDidMount(){
	if(this.props.mobile > 1025) this.selectWorkNavLi(0)
	if(document.URL.includes("category")){
		function getUrlVars() {
			var vars = {};
			window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
					vars[key] = value;
			});
			return vars;
		}
		var urlCategory = getUrlVars()["category"];
		var urlProject = getUrlVars()["project"];
		this.selectCategoryDidMount(urlCategory,urlProject)
	}
}

selectCategoryDidMount(category,project) {
	const node = ReactDOM.findDOMNode(this); 
	const categoriasSelec = node.querySelectorAll('.filter-li'); 
	const catSelec = node.querySelector('#'+ category); 
	
	this.setState({
	category:category,
	work: data[category][project],
	workGrid:data[category]
	})

	for (var i = 0; i < categoriasSelec.length; i++ ) {   
	categoriasSelec[i].className = categoriasSelec[i].className.replace("filter-li-active", "");
	}
	catSelec.className += " filter-li-active";
	this.selectWorkNavLi(project)
}

///////////////////////////////////////////////////////Loader
onloadImagesTrue(){
	const node = ReactDOM.findDOMNode(this);
	const loadArrayWorks = node.querySelectorAll('.img-load-works'); 
	loadedImg ++
	if(loadedImg === loadArrayWorks.length){
			console.log(loadedImg,loadArrayWorks.length);
			this.setState({loading:false})
			this.loader();
			setTimeout(
				function() {
					this.loader();
				}.bind(this),
				100
			);
	}
}

loader(){
	const loader = document.querySelector('.cont-loader');
	if( this.state.loading === true){
		const timeline = new Timeline({ paused: true});
		timeline.to(loader, 0.5, {opacity:1})
		timeline.play();
	} else if(this.state.loading === false){
		const timeline = new Timeline({ paused: true});
		timeline.to(loader, 0.5, {opacity:0})
		timeline.play();
	}
}

///////////////////////////////////////////////////////Loader
selectCategory(e) {

	const node = ReactDOM.findDOMNode(this); 
	var category = e.currentTarget.id;
	const categoriasSelec = node.querySelectorAll('.filter-li'); 

	this.setState({
		category:category,
		work: data[category][0],
		workGrid:data[category]
	})

	for (var i = 0; i < categoriasSelec.length; i++ ) {   
	categoriasSelec[i].className = categoriasSelec[i].className.replace("filter-li-active", "");
	}
	e.currentTarget.className += " filter-li-active";
	if(this.props.mobile > 1025){ this.selectWorkNavLi(0)}
}


selectWorkNavLi(liSeleccion){
	const node = ReactDOM.findDOMNode(this);
	const liSelec = node.querySelectorAll('.work-nav-li');
	for (var i = 0; i < liSelec.length; i++ ) {   
		liSelec[i].className = liSelec[i].className.replace("work-nav-li-active", "");
	}
	liSelec[liSeleccion].className += " work-nav-li-active";
}

nextWork() {
	var newIndex 
	var objetoArray = this.state.category;
	if(this.state.work.index < data[objetoArray].length-1){
		newIndex = this.state.work.index+1;
		this.setState({ work: data[objetoArray][newIndex] })
		if(this.props.mobile > 1025){ this.selectWorkNavLi(newIndex)}
	}
}
prevWork () {
	var newIndex 
	var objetoArray = this.state.category;
	if(this.state.work.index >= 1){
		newIndex = this.state.work.index-1;
		this.setState({ work: data[objetoArray][newIndex] })
		if(this.props.mobile > 1025){ this.selectWorkNavLi(newIndex)}
	}
}

handleClickWorks(newIndex){
	var objetoArray = this.state.category;
	this.setState({ work: data[objetoArray][newIndex] })
	if(this.props.mobile > 1025){ this.selectWorkNavLi(newIndex)}
}

hoverWorks(e){
 const animar = e.currentTarget.querySelector('.button-project');
 hoverWorksIN(animar);
}
hoverWorksOut(e){
 const animar = e.currentTarget.querySelector('.button-project');
 hoverWorksOut(animar);
}

	render() {
	const { t } = this.props;
	let wWeb;
	const {work,workGrid} = this.state;
	const mobile = this.props.mobile;
	const liNavWork = this.state.workGrid.map(({index}) => <div key={index+"listNav"} id={index+"listNavID"} className="cont-li-works" onClick={() => {this.handleClickWorks(index)} }><li   className="work-nav-li"></li></div> );

	WheelReact.config({
		up: () => {
			this.nextWork();
		},
		down: () => {
			this.prevWork();
		}
	});

	wWeb = workGrid.map(({ index,id,header,imagen,descripcion }) => {
		return   ( 
			<TransitionGroup key={id+"gridWorkTranssdsd"}>
				<Transition            
					key={id+"gridWorkTrans"}
					appear={true}
					onEnter={(node, appears) => enterGrid(node)}
					onExit={(node, appears) => exitGrid(node)}
					timeout={{enter: 2000, exit: 2000}}
					>
					<Work
						key={id+"gridWork"}
						work={workGrid[index]}
						handleHoverWorks={this.hoverWorks}
						handleHoverWorksOut={this.hoverWorksOut}
						/>
				</Transition>
			</TransitionGroup>
		)
		});

		return (
			<div className="works-cont" id="worker">
				<div onLoad={this.onloadImagesTrue} className="carga-img" >
					<img className="img-load-works" alt="" src={Guitar_01}></img>
					<img className="img-load-works" alt="" src={Guitar_02}></img>
					<img className="img-load-works" alt="" src={Guitar_03}></img>
					<img className="img-load-works" alt="" src={Guitar_04}></img>
					<img className="img-load-works" alt="" src={Guitar_05}></img>
					<img className="img-load-works" alt="" src={Guitar_06}></img>
					<img className="img-load-works" alt="" src={Guitar_07}></img>
					<img className="img-load-works" alt="" src={DigitalInvicta_01}></img>
					<img className="img-load-works" alt="" src={DigitalInvicta_02}></img>
					<img className="img-load-works" alt="" src={DigitalInvicta_03}></img>
					<img className="img-load-works" alt="" src={DigitalInvicta_04}></img>
					<img className="img-load-works" alt="" src={DigitalInvicta_gif_01}></img>
					<img className="img-load-works" alt="" src={DigitalInvicta_gif_02}></img>
					<img className="img-load-works" alt="" src={SevenAttitudes_01}></img>
					<img className="img-load-works" alt="" src={SevenAttitudes_02}></img>
					<img className="img-load-works" alt="" src={SevenAttitudes_03}></img>
					<img className="img-load-works" alt="" src={Asymmetry_01}></img>
					<img className="img-load-works" alt="" src={Asymmetry_02}></img>
					<img className="img-load-works" alt="" src={Asymmetry_03}></img>
					<img className="img-load-works" alt="" src={Asymmetry_04}></img>
					<img className="img-load-works" alt="" src={Vlp_01}></img>
					<img className="img-load-works" alt="" src={Vlp_02}></img>
					<img className="img-load-works" alt="" src={Vlp_03}></img>
					<img className="img-load-works" alt="" src={Vlp_04}></img>
					<img className="img-load-works" alt="" src={Vlp_05}></img>
					<img className="img-load-works" alt="" src={StoryDots_00}></img>
					<img className="img-load-works" alt="" src={StoryDots_01}></img>
					<img className="img-load-works" alt="" src={StoryDots_02}></img>
					<img className="img-load-works" alt="" src={Batman_01}></img>
					<img className="img-load-works" alt="" src={Scorpion_02}></img>
					<img className="img-load-works" alt="" src={Scorpion_03}></img>
					<img className="img-load-works" alt="" src={Scorpion_04}></img>
					<img className="img-load-works" alt="" src={Comic_scfi_01}></img>  
					<img className="img-load-works" alt="" src={Logos_01}></img>
					<img className="img-load-works" alt="" src={Logos_02}></img>
					<img className="img-load-works" alt="" src={Logos_03}></img>
					<img className="img-load-works" alt="" src={Logos_04}></img>
					<img className="img-load-works" alt="" src={Logos_05}></img>
					<img className="img-load-works" alt="" src={Logos_06}></img>
					<img className="img-load-works" alt="" src={Logos_07}></img>
					<img className="img-load-works" alt="" src={Logos_08}></img>
					<img className="img-load-works" alt="" src={Logos_09}></img>
					<img className="img-load-works" alt="" src={Logos_10}></img>
					<img className="img-load-works" alt="" src={Logos_11}></img>
					<img className="img-load-works" alt="" src={Logos_12}></img>
				</div>

				<div className="categoryName">{this.state.category}</div>
				<TransitionGroup>
					<Transition
						key={work.id + "cover"}
						timeout={{enter: 1000, exit:1000}}
						appear={true}
						onEnter={(node, appears) => enterCover(node)}
						onExit={(node, appears) => exitCover(node)}
					>
					<img  className="coverWorks" alt={work.id}  src={work.imagen} ></img>
					</Transition>
				</TransitionGroup>
				<div className="works-subcont" >
					<div className="filter-cont">
						<div className="filter-subcont">
							<li className="filter-li filter-li-active" id="web" onClick={this.selectCategory}>Web</li>
							<li className="filter-li"  id="design" onClick={this.selectCategory}>{t("Design")}</li>
							<li className="filter-li" id="photo" onClick={this.selectCategory}>{t("Photo")}</li>
							{/*<li className="filter-li" id="personal" onClick={this.selectCategory}>Personal</li>*/}
						</div>
					</div>
					<div className="sliders-cont">
						<div className="cont-miniaturas" >
							{mobile < 1025 ? [wWeb] :
								<div>
									<TransitionGroup {...WheelReact.events}>
										<Transition
											key={work.id +"trabajo"}
											timeout={{enter: 2000, exit: 1000}}
											appear={true}
											onEnter={(node, appears) => enterSlider(node)}
											onExit={(node, appears) => exitSlider(node)}
										>
											<Work key={work.id +"subtrabajo"}  work={work}  />
										</Transition>
									</TransitionGroup>
								</div>
							}
						</div> 
						<div className="nav-Work-cont">{liNavWork}</div>
					</div>
				</div>
			</div>
		)
	}
}

export default (withTranslation("translations")(Works));