import React from 'react';
import './projects.css'
import data from '../data/data'
import { NavLink } from 'react-router-dom';
import { withTranslation} from "react-i18next";

class Concept extends React.Component {


render(){
const { t } = this.props;
const category = this.props.category;
const index = this.props.index;
const project = data[category][index];

const galery = project.expandImgArray.map(item => <div key={item} className="cont-img-galery"><img src={"../" + item} alt="Concept" className="img-galery"></img></div> );
  
	return (
		<div className="work-page-cont Concept">
    <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>
      
        <div className="work-page-subcont dark">
        <div className="headers-work-page">{project.header}</div>
        <img className="main-image" src={"../" + project.imagen} alt="Concept"></img>
        </div>

        <div className="work-page-project dark">

              <div className="project-header-cont">
                    <div className="project-header-subcont">
                        <p className="project-header">{t("Project")}</p>
                        <p className="project-description-text">{t("Concept art for videogame")}</p>
                    </div>

                     <div className="large-description">

              </div>
              </div>

              <div className="project-bullets">
                  <div className="project-bullets-header">{t("Tools")}:</div>
                   <ul>
                        <li>Digital Painting</li>
                        <li>Photoshop</li>
                  </ul>
              </div>

             

        </div>

        <div className="work-page-galery dark" >

         {galery}

        </div>
        <NavLink className="work-page-button "  strict exact to={"/works?category="+category +"&project="+index} >{t("Back")}</NavLink>

      </div>
		)
}
}


export default (withTranslation("translations")(Concept));